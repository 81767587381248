<template>
  <div class="termos-de-aceite">
    <div class="breadcrumb mb-6">
      <breadcrumb title="Termos de aceite" actualPage="Termos de aceite" />
    </div>

    <div class="w-full content p-4 termos-de-aceite-tabs">
      <vs-tabs>
        <vs-tab label="Termos de Aceite" class="p-4">
          <TermosDeAceite :dadosCorretor="userInfo" />
        </vs-tab>
      </vs-tabs>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Breadcrumb from "@/layouts/components/breadcrumb/Breadcrumb.vue";
import TermosDeAceite from "../../components/termos-aceite/TermosDeAceite.vue";


export default {
  components: {
    breadcrumb: Breadcrumb,
    TermosDeAceite
  },
  computed: {
    ...mapGetters("auth", ["userInfo"])
  },
  created() {
    this.$appInsights.trackPageView({
      name: "termosDeAceite",
      url: window.location.href
    });
  }
};
</script>

<style lang="scss">
.termos-de-aceite-tabs {
  background: white;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.vs-tabs--ul {
  border-bottom: 2px solid #d9d9d9 !important;
  box-shadow: none;
}
</style>
