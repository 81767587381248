var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "termos-de-aceite" }, [
    _c(
      "div",
      { staticClass: "breadcrumb mb-6" },
      [
        _c("breadcrumb", {
          attrs: { title: "Termos de aceite", actualPage: "Termos de aceite" },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "w-full content p-4 termos-de-aceite-tabs" },
      [
        _c(
          "vs-tabs",
          [
            _c(
              "vs-tab",
              { staticClass: "p-4", attrs: { label: "Termos de Aceite" } },
              [
                _c("TermosDeAceite", {
                  attrs: { dadosCorretor: _vm.userInfo },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }